import {
  Container,
  FormControl,
  Grid,
  MenuItem,
  Select,
} from "@material-ui/core";
import React, {
  useState,
  useEffect
} from "react";
import toast from 'react-simple-toasts';
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";

import {
  Link,
  useLocation,
  useParams,
  useHistory
} from "react-router-dom";
import Slider from "react-slick";

import "./ProductDetail.css";

import {
  Add,
  Check,
  ChevronRight,
  Close,
  Edit,
  KeyboardArrowRight,
  LocalShipping,
  Remove,
  StarRounded,
} from "@material-ui/icons";

import avatar from "../../assets/images/avantar.png";

import {
  get_product_detail,
  get_product_variant,
  get_product_variant_selection,
  get_product_price_type,
  get_installment_period,
} from "../../api/API";
import useCart from "../../hooks/useCart";
import Product001 from "../../assets/images/product01.jpg"
import Product002 from "../../assets/images/product06.jpg"

export default function ProductDetail() {
  let history = useHistory();
  let { product_id } = useParams();

  let { ProductDetail } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [ProductDetail]);

  //
  const settings2 = {
    arrows: false,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,

        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,

        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,

          dots: false,
        },
      },
    ],
  };

  const { addProduct, clearCart } = useCart();

  // DISPLAY PRODUCT DETAIL

  const [productDetail, setProductDetail] = useState(null)
  const [productbanner, setProductbanner] = useState([])
  const [selectedVariantSelection, setselectedVariantSelection] = useState(null)
  const [branchData, setBranchData] = useState(null)
  const [selectedBranch, setSelectedBranch] = useState(null)
  const [selectedDeliveryType, setSelectedDeliveryType] = useState(1)

  const [productVariant, setProductVariant] = React.useState([]);
  const [tabPhoto, setTabPhoto] = React.useState(null);

  const [isLoading, setIsLoading] = React.useState(false);
  const [chooseSelectionOption, setChooseSelectionOption] = React.useState({});

  const [count, setCount] = React.useState(1);
  const [stock, setStock] = useState(0)

  const getProduct = async () => {
    setIsLoading(true);

    var resDetail = await get_product_detail({ product_id: product_id });
    if (resDetail.status) {
      setProductDetail(resDetail.data)
      setProductbanner(resDetail.data.image)
      setTabPhoto(resDetail.data.image[0].image)
      setProductVariant(resDetail.data.variation[0])
      setselectedVariantSelection(resDetail.data.variation[0].selection[0])
      setBranchData(resDetail.data.variation[0].selection[0].branch)
      setSelectedBranch(resDetail.data.variation[0].selection[0].branch[0])
      setStock(resDetail.data.variation[0].selection[0].branch[0].stock)
      setIsLoading(false);
    }
  };

  //Change Branch
  const handleChangeBranch = (item) => {
    setSelectedBranch(item)
    setStock(item.stock)
  }

  const handleChangeTabPhoto = (text) => {
    setTabPhoto(text);
  };

  // QUANTITY
  const handleChangeQuantity = (new_qty) => {
    if (new_qty < 1) {
      return;
    }
    // console.log(new_qty > count, new_qty, count)
    if (new_qty > stock) {
      toast('Stock not enough');
      return;
    }
    setCount(new_qty);
  };

  const handleAddCart = () => {
    var cartArray = {
      product_id: 1,
      variation_id: 1,
      product_variation_id: 1,
      product_image: Product001,
      // variant: "testv",
      // variant_selection: "tests",
      product: "FAST DELIVERY Daikin R32 Inverter (FTKF Series)",
      quantity: count,
      installment_rate: "108.67",
      installment_rate_2: "56.57",
      cash_rate: "1154.00",
      online_payment_rate: "1202.00",
      is_installation: deliveryOption,
    };
    console.log('cartArray', cartArray);
    addProduct(cartArray);
    toast('Added to cart');
  };

  React.useEffect(() => {
    getProduct();
  }, []);

  const [deliveryOption, setDeliveryOption] = React.useState(1);

  //Change installation
  const handleCheckDelivery = (item) => {
    setSelectedDeliveryType(item.id)
  }

  return (
    <React.Fragment>
      <Header />
      <Container>
        {/* <div className="theLinkC">
            <Link className="theLink">
              Home
              <KeyboardArrowRight />
              LOREM IPSUM
              <KeyboardArrowRight />
              LOREM IPSUM
              <KeyboardArrowRight />
              LOREM IPSUM
            </Link>
          </div> */}
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={5}>
            <div className="theProdBox">
              <div className="theProdIMG">
                <img src={tabPhoto} />
              </div>
              <div className="theGallery">
                <>
                  {productbanner != null && productbanner.map((res) =>
                    <div
                      className={
                        tabPhoto == res.image
                          ? "theGalleryProdIMG"
                          : "theGalleryProdIMG"
                      }
                      onClick={() => {
                        handleChangeTabPhoto(res.image);
                      }}
                    >
                      <img src={res.image} />
                    </div>
                  )}
                  {/* <div
                    className={
                      tabPhoto == Product002
                        ? "theGalleryProdIMG"
                        : "theGalleryProdIMG"
                    }
                    onClick={() => {
                      handleChangeTabPhoto(Product002);
                    }}
                  >
                    <img src={Product002} />
                  </div> */}
                </>
              </div>
            </div>
          </Grid>
          {selectedVariantSelection != null &&
            <Grid item xs={12} sm={12} md={7}>
              <div className="theProdBox">
                <h2 style={{ margin: 0 }}>{productDetail.name}</h2>
                {/* <div className="theProdRate">
                  <div className="theFlex_">
                    <p style={{ color: '#000' }}>4.9 <span style={{ color: '#767676' }}>STAR</span></p>
                  </div>
                  <div className="theFlex_">
                    <p style={{ color: '#000' }}>100 <span style={{ color: '#767676' }}>SOLD</span></p>
                  </div>
                </div> */}
                <div className="thePriceBox">
                  <h2 style={{
                    color: '#00bfec',
                  }}>
                    Cash: RM {parseInt(selectedVariantSelection.normal_price).toFixed(2)}
                  </h2>
                  <h2 style={{
                    color: '#E6B10E',
                  }}>
                    Online Payment: RM {parseInt(selectedVariantSelection.instant_price).toFixed(2)}
                  </h2>
                  <h2 style={{
                    color: '#e50b2c',
                  }}>
                    Installment (12 months): RM {parseInt(selectedVariantSelection.install_price_12 / 12).toFixed(2)} / month
                  </h2>
                  <h2 style={{
                    color: '#e50b2c',
                  }}>
                    Installment (24 months): RM RM {parseInt(selectedVariantSelection.install_price_24 / 24).toFixed(2)} / month
                  </h2>
                </div>
                <div className="theProductDetail">

                  <div className="theFlex_" style={{ alignItems: "center" }}>
                    <p className="label">Quantity</p>
                    <div className="theQuantityContainer">
                      <div
                        className="theIncrement"
                        onClick={() => handleChangeQuantity(count - 1)}
                      >
                        <Remove />
                      </div>
                      <p className="theCount">{count}</p>
                      <div
                        className="theIncrement"
                        onClick={() => handleChangeQuantity(count + 1)}
                      >
                        <Add />
                      </div>
                    </div>
                    <p style={{ color: "#757575" }}>
                      {chooseSelectionOption.stock} available
                    </p>
                  </div>
                  <div
                    className="theCartContainer"
                    onClick={() => handleAddCart()}
                  >
                    <div className="theCartBtn">Add to Cart</div>
                  </div>
                </div>
              </div>
            </Grid>
          }
          {/* {product.add_on != undefined && product.add_on != '' &&
            <Grid item xs={12} sm={12} md={12}>
              <div className="theAddonBox"
                onClick={() => handleNavAddOn()}
              >
                <div>
                  <h3 style={{ color: "#757575" }}>Add-on Deals</h3>
                </div>
                <div className="c-slider_">
                  <Slider {...settings2}>
                    <div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className="c-theSliderBox">
                          <img src={product.image[0].image} />
                          <p className="sub">{product.name}</p>
                        </div>
                        <Add />
                      </div>
                    </div>
                    {product.add_on.map((item) => (
                      <div>
                        <Link to={`../productdetail/${item.product_id}`}>
                          <div className="c-theSliderBox">
                            <img src={item.image} />
                            <p className="sub">{item.name}</p>
                            <p className="sub">RM {item.total_price}</p>
                          </div>
                        </Link>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </Grid>
          } */}
        </Grid>
      </Container >
      <Footer />
    </React.Fragment >
  );
}

export const deliverytype = [
  { id: "1", name: "Installation" },
  { id: "2", name: "Without Installation" },
];
