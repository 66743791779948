import React from "react";
import {
  Container,
  Grid,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { ChevronRight } from "@material-ui/icons";
import { Link, useLocation, useParams } from "react-router-dom";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import background from "../../assets/images/backgroundCat.png";
import prod001 from "../../assets/images/wall-mounted.png";
import "./Category.css";
import Product001 from "../../assets/images/product01.jpg"
import Product002 from "../../assets/images/product02.jpg"
import Product003 from "../../assets/images/product03.jpg"
import Product004 from "../../assets/images/product04.jpg"
import Product005 from "../../assets/images/product05.jpg"

import { get_category_by_id, get_recommend_product, get_category, filter_product } from "../../api/API";

export default function Category() {
  let { Category } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [Category]);

  let { category_id } = useParams();

  const [tabs, setTabs] = React.useState(1);

  const handleChangeTab = (text) => {
    setTabs(text);
  };

  // GET CATEGORY

  const [category, setCategory] = React.useState([]);
  const [categoryName, setCategoryName] = React.useState([]);
  React.useEffect(() => {
    get_category().then((json) => {
      setCategory(json.data);
      setTabs(json.data[0].category_id);
    });
  }, []);

  // FILTER PRODUCT

  const [sortList, setSortList] = React.useState(1);

  const [pageList, setPageList] = React.useState(1);

  const [productByCategory, setProductByCategory] = React.useState([]);

  const [pageNum, setPageNum] = React.useState("");

  const handleChangeSort = (event) => {
    setSortList(event.target.value);
    console.log(sortList);
  };

  const handleChangePage = (event, ispage) => {
    setPageList(ispage);
    let postparm = {
      sort: sortList,
      category_id: tabs,
      page: ispage,
    };
    filter_product(postparm).then((json) => {
      setProductByCategory(json.data);
    });
  };

  React.useEffect(() => {
    let postparm = {
      sort: sortList,
      page: pageList,
      category_id: tabs,
    };
    filter_product(postparm).then((json) => {
      setProductByCategory(json.data);
      // setCategoryName(json.data[0].category);
      setPageNum(json.pages);
    });
  }, [sortList, tabs]);

  return (
    <React.Fragment>
      <Header />
      <div className="theBackground">
        <img src={background} />
        <div className="theOverlay">
          <div className="theTitle">
            <p>Our Product</p>
          </div>
        </div>
      </div>
      <Container>
        <div className="theCategorySection">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={3}>
              <div className="theCategoryList">
                <p className="theTitle">ALL CATEGORY</p>
                {category.map((item) => (
                  <div
                    style={{ cursor: 'pointer' }}
                    className={
                      tabs === item.category_id ? "theLinkActive" : "theLink"
                    }
                    onClick={() => handleChangeTab(item.category_id)}
                  >
                    <p>{item.name}</p>
                    <ChevronRight className="theIcon" />
                  </div>
                ))}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={9}>
              {tabs === tabs && (
                <div className="theProductList">
                  <div className="theFlexHeader">
                    <div className="theCategory_">
                      <p className="category">{categoryName}</p>
                    </div>

                    <div className="theSort">
                      <p>Sort By:</p>
                      <FormControl className="theFormControlSelect">
                        <Select
                          onChange={handleChangeSort}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          value={sortList}
                        >
                          <MenuItem value="1">Low to High</MenuItem>
                          <MenuItem value="2">High to Low</MenuItem>
                          <MenuItem value="3">A to Z</MenuItem>
                          <MenuItem value="4">Z to A</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className="theProductListing">
                    <Grid container spacing={3}>
                      {/* {productByCategory.map((item) => (
                        <Grid item xs={12} sm={4} md={4}>
                          <Link to={`../productdetail/${item.product_id}`}>
                            <div className="theCardProduct">
                              <div className="theProductIMG">
                                <img src={item.image} />
                              </div>
                              <div className="theProductDetail">
                                <p className="product">{item.name}</p>
                                <p className="color">{item.category}</p>
                                <p className="price">RM {item.product_price}</p>
                              </div>
                            </div>
                          </Link>
                        </Grid>
                      ))} */}
                      <Grid item xs={12} sm={4} md={4}>
                        <Link to={`../productdetail/1`}>
                          <div className="theCardProduct">
                            <div className="theProductIMG">
                              <img src={Product001} />
                            </div>
                            <div className="theProductDetail">
                              <p className="product">Daikin R32 Inverter (FTKF Series)</p>
                              <p className="color">Daikin</p>
                              <p className="price">RM 1154.00</p>
                            </div>
                          </div>
                        </Link>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4}>
                        <Link to={`../productdetail/1`}>
                          <div className="theCardProduct">
                            <div className="theProductIMG">
                              <img src={Product002} />
                            </div>
                            <div className="theProductDetail">
                              <p className="product">Daikin R410 Standard Non Inverter (FTN-P Series)</p>
                              <p className="color">Daikin</p>
                              <p className="price">RM 1082.00</p>
                            </div>
                          </div>
                        </Link>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4}>
                        <Link to={`../productdetail/1`}>
                          <div className="theCardProduct">
                            <div className="theProductIMG">
                              <img src={Product005} />
                            </div>
                            <div className="theProductDetail">
                              <p className="product">Midea R32 Inverter Extreme Save (MSCS Series)</p>
                              <p className="color">Midea</p>
                              <p className="price">RM 1145.00</p>
                            </div>
                          </div>
                        </Link>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4}>
                        <Link to={`../productdetail/1`}>
                          <div className="theCardProduct">
                            <div className="theProductIMG">
                              <img src={Product003} />
                            </div>
                            <div className="theProductDetail">
                              <p className="product">Daikin R32 Premium Inverter + Wifi (FTKH Series)</p>
                              <p className="color">Midea</p>
                              <p className="price">RM 1082.00</p>
                            </div>
                          </div>
                        </Link>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4}>
                        <Link to={`../productdetail/1`}>
                          <div className="theCardProduct">
                            <div className="theProductIMG">
                              <img src={Product004} />
                            </div>
                            <div className="theProductDetail">
                              <p className="product">Daikin R32 Premium Inverter (FTKG Series)</p>
                              <p className="color">Daikin</p>
                              <p className="price">RM 1154.00</p>
                            </div>
                          </div>
                        </Link>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="thePagination">
                    <Pagination
                      page={pageList}
                      onChange={handleChangePage}
                      count={pageNum}
                      defaultPage={pageList}
                    />
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      </Container>

      <Footer />
    </React.Fragment>
  );
}
